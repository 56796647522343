<template>
  <div v-show="form.activities.includes('tarp_on_pull_out')" class="field p-fluid">
    <Divider />
    <label for="tarps">Tarp Serial Number(s)</label>
    <p class="subtitle">
        Select all tarps put on stack
    </p>

    <div class="flex flex-col flex-wrap gap-2">
      <Chip v-for="tarp in value" :key="tarp.id" 
        :label="tarp.serial_no" 
        removable
        @remove="unselectTarp(tarp)"
      />
      <Chip 
        label="+ Select tarp"
        :pt="{
          'root': {
            'style': {
              'border': '1px dashed grey',
              'background-color': 'white',
              'cursor': 'pointer'
            },
          }
        }"
        @click="openSelectTarpModal"
      />
      <input @focus="openSelectTarpModal" style="width: 1px; height: 1px; border: none; box-shadow: none" />
    </div>

    <Message v-if="onStackTarpsSelected.length > 0" severity="error" :closable="false">
      <p>
        You've selected tarps that are recorded as being on another stack
        ({{ onStackTarpsSelected.map(t => `${t.serial_no} - ${t.stack.fullName()}`).join(', ') }}).
      </p>
      <p v-if="_isIASTenant" class="mt-1">
        Can you please physically confirm this SN is correct by taking a photo of 
        the tarp pole and sending it through to management and make a note in the 
        comments section
      </p>
    </Message>
    <Message v-if="offSiteTarpsSelected.length > 0" severity="warn" :closable="false">
      You've selected tarps that are not recorded as being on this site
      ({{ offSiteTarpsSelected.map(t => `${t.serial_no} - ${t.site.displayName()}`).join(', ') }}).
    </Message>

  </div>
        
</template>

<script>
import Chip from 'primevue/chip';
import Divider from 'primevue/divider';
import Message from 'primevue/message';

import NewTarpForm from './NewTarpForm.vue';
import SelectTarpMenu from './SelectTarpMenu.vue';

import { isIASTenant } from '@/utils/ui';

import { useSitesStore } from '@/stores/Sites';
import { useTarpInventoryStore } from '@/stores/TarpInventory';
import { useRecordTarpingWorksStore } from '@/stores/RecordTarpingWorks';

export default {
  name: "TarpsSelect",
  props: ['modelValue', 'siteId'],
  components: {
    Chip,
    Divider,
    Message,
    NewTarpForm,
    SelectTarpMenu,
  },
  data() { return {
    store: null,
    formStore: null,
    sitesStore: null,
    search_serial_no: '',
    selectTarpModal: null,
    createTarpModal: null,
  }},
  computed: {
    value() {
      const tarps = this.modelValue.map(tarpId => {
        return this.tarps.find(t => t.id === tarpId);
      }).filter(t => t !== undefined);
      return tarps.concat(this.form.new_tarps)
    },
    form() {
      return this.formStore.form;
    },
    tarps() {
      return this.store.getTarps.concat(this.form.new_tarps)
    },
    site() {
      return this.sitesStore.getSiteById(this.siteId)
    },
    offSiteTarpsSelected() {
      const offSiteTarpsSelected = this.tarps.filter(tarp => {
        const notOnStack = !tarp.stack;
        const tarpSite = tarp.site;
        return notOnStack && tarpSite && tarpSite.id !== this.site.id && this.modelValue.includes(tarp.id)
      });
      return offSiteTarpsSelected;
    },
    onStackTarpsSelected() {
      const onStackTarpsSelected = this.tarps.filter(tarp => {
          return tarp.stack && this.modelValue.includes(tarp.id)
      });
      return onStackTarpsSelected;
    },
    _isIASTenant() {
      return isIASTenant();
    }
  },
  async created() {
    this.store = useTarpInventoryStore();
    this.sitesStore = useSitesStore();
    this.formStore = useRecordTarpingWorksStore();
  },
  methods: {
    openSelectTarpModal() {
      this.selectTarpModal = this.$dialog.open(SelectTarpMenu, {
        data: {
          site: this.site
        },
        props: {
          header: "Select tarp",
          style: {
              width: '100vw',
              height: '100vh',
              maxHeight: '100vh',
              borderRadius: '0',
          },
          modal: true,
          dismissableMask: true,
          contentClass: 'flex-grow-1',
        },
        emits: {
          onCreateTarp: () => {
              this.openNewTarpModal();
          },
          onSelectTarp: (tarp) => {
            this.selectTarp(tarp);
            this.selectTarpModal.close();
          }
        },
      });
    },
    openNewTarpModal() {
      this.selectTarpModal.close();
      this.createTarpModal = this.$dialog.open(NewTarpForm, {
        props: {
          header: "Create tarp",
          style: {
            width: '100vw',
            height: '100vh',
            maxHeight: '100vh',
            borderRadius: '0',
          },
          modal: true,
          dismissableMask: true,
          contentClass: 'flex-grow-1',
        },
        emits: {
          onCreate: (tarp) => {
            this.form.new_tarps.push(tarp);
          }
        },
      });
    },
    selectTarp(tarp) {
      // Filter existing tarps to remove the tarp if it is already selected
      const newModelValue = this.modelValue.filter(tarpId => tarpId !== tarp.id);
      // Add the tarp to the model value
      newModelValue.push(tarp.id);
      this.$emit('update:modelValue', newModelValue);
    },
    unselectTarp(tarp) {
      if (tarp.id) {
        const newModelValue = this.modelValue.filter(tarpId => tarpId !== tarp.id);
        this.$emit('update:modelValue', newModelValue);
      } else {
        // Remove from new tarps
        const newTarps = this.form.new_tarps.filter(newTarp => newTarp.client_id !== tarp.client_id);
        this.form.new_tarps = newTarps;
      }
      
    },
    search(event) {
        this.search_serial_no = event.query;
    }
  }
}

</script>